export enum PackagePickerInteractions {
  CheckoutPageInitialized = 'checkout_page_initialized',
  PlanSelected = 'plan_selected',
  OrderGuestCreated = 'create_guest_order',
  OrderCreated = 'create_order',
  CreateSubmission = 'create_submission',
  SetSubmission = 'set_submission',
  CashierWidgetLoaded = 'cashier_widget_loaded',
  CustomFormAsyncJob = 'custom_form_async_job',
}
